@tailwind base;
@tailwind components;
@tailwind utilities;

/*all the buttons based on the theme*/

.primary-button {
    @apply text-white bg-pink-500 hover:bg-pink-600 transition duration-300 focus:outline-none focus:ring-2 focus:ring-pink-300;
}

.unique-button {
    @apply bg-gradient-to-r from-green-500 to-blue-500 text-white shadow-xl hover:shadow-2xl hover:scale-105 transform transition duration-300 z-50 flex items-center space-x-2;
}

.secondary-button {
    @apply font-semibold text-white bg-gradient-to-r from-blue-500 to-indigo-600 rounded-lg shadow-lg hover:from-blue-600 hover:to-indigo-700 transition-all duration-300 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2;
}